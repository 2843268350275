/* eslint-disable @typescript-eslint/no-floating-promises */

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from 'services/authorization.service';
import { CentralServerService } from 'services/central-server.service';
import { LanguageService } from 'services/language.service';
import { MessageService } from 'services/message.service';
import { SpinnerService } from 'services/spinner.service';
import { ChargingStation } from 'types/ChargingStation';
import { Paging, PricingDefinitionDataResult } from 'types/DataResult';
import { FilterParams } from 'types/GlobalType';
import { HTTPError } from 'types/HTTPError';
import { User, UserRole, UserWithPassword } from 'types/User';
import { Utils } from 'utils/Utils';

import { WindowService } from '../../services/window.service';
import { AbstractTabComponent } from '../../shared/component/abstract-tab/abstract-tab.component';

@Component({
  selector: 'app-qrcode',
  templateUrl: 'qrcode.component.html',
  styleUrls: ['qrcode.component.scss'],
})
export class QrCodeComponent extends AbstractTabComponent implements OnInit {

  public canListTags: boolean;
  public pricingDefinitions: any;
  public chargingStation: ChargingStation | null = null;
  public connectorId: number;
  public connectorExists = true;
  public connectorStatus: string;
  public browserLanguage: string;
  public connectorStatusColor: string;
  public chargingStationId: string;
  private isLoggedIn = false;
  public constructor(
    activatedRoute: ActivatedRoute,
    windowService: WindowService,
    private router: Router,
    private location: Location,
    private centralServerService: CentralServerService,
    private spinnerService: SpinnerService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private authorizationService: AuthorizationService,
  ) {
    super(activatedRoute, windowService, ['all']);
  }

  // Getters
  get hasFlatFee(): boolean {
    return this.pricingDefinitions?.flatFee?.active && this.pricingDefinitions.flatFee.price != null;
  }

  get hasEnergy(): boolean {
    return this.pricingDefinitions?.energy?.active && this.pricingDefinitions.energy.price != null;
  }

  get hasChargingTime(): boolean {
    return this.pricingDefinitions?.chargingTime?.active && this.pricingDefinitions.chargingTime.price != null;
  }

  get hasParkingTime(): boolean {
    return this.pricingDefinitions?.parkingTime?.price != null;
  }

  redirectToEv24(): void {
    window.location.href = 'https://www.ev24.io/support/';
  }

  redirectToPayment(): void {
    const entityId = this.activatedRoute.snapshot.paramMap.get('entityId');
    const connectorId = this.activatedRoute.snapshot.paramMap.get('connectorId');

    if (entityId && connectorId) {
      this.router.navigate([`/qrcode/${entityId}/${connectorId}/payment`]);
    } else {
      console.error('Entity ID or Connector ID is missing');
    }
  }

  goBack(): void {
    this.location.back();
  }
  ngOnInit(): void {
    this.checkLoginAndLoadData();
  }

  private checkLoginAndLoadData(): void {
    const role = this.authorizationService.getUserRole();
    console.log('QrCodeComponent ~ checkLoginAndLoadData ~ role:', role);
    this.setupLanguagePreferences();
    const entityId = this.activatedRoute.snapshot.paramMap.get('entityId');
    const connectorId = this.activatedRoute.snapshot.paramMap.get('connectorId');

    if (entityId) {
      this.loadPricingDefinitions(entityId);
      if (connectorId) {
        this.loadChargingStation(entityId, Number(connectorId));
      } else {
        console.error('Connector ID is missing in the URL');
      }
    }
  }

  private setupLanguagePreferences(): void {
    this.browserLanguage = this.languageService.getBrowserLanguage() || 'fr';
    localStorage.setItem('language', this.browserLanguage);
    const supportedLanguages = ['en', 'fr', 'es', 'de', 'it', 'pt', 'cs', 'cz'];
    const baseLanguage = this.browserLanguage.split('-')[0];
    const languageToUse = supportedLanguages.includes(baseLanguage) ? baseLanguage : 'en';
    this.translateService.use(languageToUse);
  }

  private loadPricingDefinitions(entityId: string): void {
    const params: FilterParams = {};
    const paging: Paging = { limit: 10, skip: 0 };
    const ordering = [];

    this.centralServerService.getPricingDefinitionsQRcode(params, paging, ordering, { entityID: entityId, entityType: 'ChargingStation' }).subscribe({
      next: (data: PricingDefinitionDataResult) => {
        if (data && data.count > 0) {
          this.pricingDefinitions = data.result[0].dimensions;
        }
      },
      error: (error) => {
        console.error('Failed to load pricing definitions:', error);
      },
    });
  }

  private loadChargingStation(entityId: string, connectorId: number): void {
    this.centralServerService.getChargingStationQr(entityId).subscribe({
      next: (chargingStation: ChargingStation) => {
        this.chargingStation = chargingStation;
        this.connectorExists = chargingStation.connectors.some((connector) => connector.connectorId === connectorId);
        this.connectorId = connectorId;
        const connectorTest = Utils.getConnectorFromID(chargingStation, connectorId);

        if (connectorTest) {
          const statusKey = `connectorStatus.${connectorTest.status.toUpperCase()}`;
          this.translateService.get(statusKey).subscribe((translatedStatus: string) => {
            this.connectorStatus = translatedStatus || connectorTest.status;
            this.applyStatusColor(connectorTest.status.toUpperCase());
            this.connectorExists = true;
          });
        } else {
          this.connectorExists = false;
          this.connectorStatus = '';
        }

        if (!this.connectorExists) {
          this.spinnerService.hide();
        }
      },
      error: (error) => {
        console.error('Failed to load charging station:', error);
        this.spinnerService.hide();
        this.connectorExists = false;
        this.connectorStatus = '';
      },
    });
  }
  private applyStatusColor(status: string): void {
    const statusColorMap = {
      UNAVAILABLE: 'red',
      AVAILABLE: 'green',
      BLOCKED: 'black',
      CHARGING: 'blue',
      INOPERATIVE: 'gray',
      OUTOFORDER: 'darkred',
      PLANNED: 'purple',
      REMOVED: 'orange',
      RESERVED: 'darkblue',
      UNKNOWN: 'darkgray'
    };
    this.connectorStatusColor = statusColorMap[status] || 'default-color';
  }

  // private async login(email, password): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     this.spinnerService.show();
  //     this.authorizationService.cleanUserAndUserAuthorization();
  //     const loginUser: UserWithPassword = {
  //       email,
  //       id: '',
  //       issuer: false,
  //       name: '',
  //       firstName: '',
  //       fullName: '',
  //       tags: [],
  //       plateID: '',
  //       phone: undefined,
  //       mobile: '',
  //       notificationsActive: false,
  //       notifications: undefined,
  //       address: undefined,
  //       iNumber: '',
  //       costCenter: false,
  //       status: '',
  //       image: '',
  //       role: UserRole.ADMIN,
  //       locale: '',
  //       language: '',
  //       numberOfSites: 0,
  //       scopes: [],
  //       companies: [],
  //       sites: [],
  //       sitesAdmin: [],
  //       sitesOwner: [],
  //       userHashID: 0,
  //       tenantHashID: 0,
  //       eulaAcceptedHash: '',
  //       eulaAcceptedVersion: 0,
  //       eulaAcceptedOn: undefined,
  //       billingData: undefined,
  //       technical: false,
  //       freeAccess: false,
  //       password,
  //       acceptEula: true,
  //     };

  //     this.centralServerService.login(loginUser).subscribe(
  //       (result) => {
  //         this.spinnerService.hide();
  //         this.centralServerService.loginSucceeded(result.token);
  //         this.isLoggedIn = true;
  //         resolve();
  //         const entityId = this.activatedRoute.snapshot.paramMap.get('entityId');
  //         const connectorId = Number(this.activatedRoute.snapshot.paramMap.get('connectorId'));

  //         if (entityId) {
  //           this.loadPricingDefinitions(entityId);
  //           this.loadChargingStation(entityId, connectorId);
  //         } else {
  //           console.error('Entity ID is missing in the URL');
  //         }
  //       },
  //       (error) => {
  //         this.spinnerService.hide();
  //         reject(error);
  //       }
  //     );
  //   }
  //   );
  // }
}
