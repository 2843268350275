import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'services/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthorizationService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const allowedRoles = route.data['roles'] as string[];
    const setRole = route.data['setRole'] as string;
    let userRole = this.authService.getUserRole();

    console.log('RoleGuard ~ Current user role:', userRole);

    // If we're setting the role to 'Q', do it and allow access
    if (setRole === 'Q' && allowedRoles.includes('Q')) {
      this.authService.setUserRole('Q');
      userRole = 'Q';
      console.log('RoleGuard ~ Role set to Q');
    }

    // If user role is 'Q', only allow access to QrCode components
    if (userRole === 'Q' && !state.url.includes('/qrcode')) {
      console.log('RoleGuard ~ Q user tried to access non-QrCode route');
      return this.router.createUrlTree(['/browser-not-supported']);
    }

    // Check if the user's role is allowed for this route
    if (allowedRoles.includes(userRole)) {
      return true;
    } else {
      console.log('RoleGuard ~ Access denied for role:', userRole);
      return this.router.createUrlTree(['/browser-not-supported']);
    }
  }
}
